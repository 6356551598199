<template>
  <div class="container mt-5">
    <!--breadcrumbs-->
    <div
      class="row align-items-center jutify-content-start mt-4 paragraph-03 text-neutral-05 mb-4 pb-3"
    >
      <div class="col-auto pe-2">
        <router-link :to="{ name: 'HomePage' }" class="text-neutral-05"> Acasă </router-link>
      </div>
      <div class="col-auto pe-2">
             <img src="../../assets/images/icons/caret-right-gray-16x16.svg"/>
      </div>
      <div class="col-auto pe-2">
        <a
          href="/blog"
          :class="[
            filter.Category && filter.Category.Id
              ? 'text-neutral-05'
              : 'text-secondary-05 text-button-02',
          ]"
        >
          Articole
        </a>
      </div>
      <div v-if="filter.Category && filter.Category.Id" class="col-auto pe-2">
        <img src="../../assets/images/icons/caret-right-gray-16x16.svg"/>
      </div>
      <div
        v-if="filter.Category && filter.Category.Id"
        class="text-button-02 col-auto text-secondary-05"
      >
        {{ filter.Category.Name }}
      </div>
    </div>
    <div class="row align-items-center mb-3 mb-md-4 pb-md-2">
      <div class="col mb-4 mb-md-0">
        <div class="heading-01 text-secondary-03 d-none d-sm-block">Articole</div>
        <div class="heading-03 text-secondary-03 d-sm-none">Articole</div>
      </div>
      <div class="col-md col-xl-4 col-xxl-3">
        <div class="input-group filter-border left">
          <span class="input-group-text">
            <img src="../../assets/images/icons/search-linear-24x24.svg" alt="..." />
          </span>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Cautare..."
            v-model="filter.SearchQuery"
            @keyup.enter="GetBlogs"
          />
        </div>
      </div>
    </div>
    <div class="row mb-4 pb-2">
      <div class="col-md-6 col-xl-3 mb-3 mb-xl-0">
        <div class="filter-border" :class="{ 'multiselect-value-selected': filter.Category }">
          <VueMultiselect
            v-model="filter.Category"
            class="custom-multiselect"
            :options="categoriesList"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            placeholder="Toate categoriile"
            select-label=""
            @search-change="GetListOfCategories"
            @open="GetListOfCategories()"
            @select="GetBlogs()"
            @remove="GetBlogs()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Category = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 mb-3 mb-xl-0">
        <div
          class="filter-border"
          :class="{ 'multiselect-value-selected': filter.Tags && filter.Tags.length > 0 }"
        >
          <VueMultiselect
            v-model="filter.Tags"
            class="custom-multiselect"
            :options="tagsList"
            :close-on-select="true"
            placeholder="Toate tagurile"
            select-label=""
            @search-change="GetListOfTags"
            @open="GetListOfTags()"
            @select="GetBlogs()"
            @remove="GetBlogs()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Tags = [];
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md-6 col-xl-3 mb-3 mb-md-0">
        <div class="filter-border" :class="{ 'multiselect-value-selected': filter.Author }">
          <VueMultiselect
            v-model="filter.Author"
            class="custom-multiselect"
            :options="authorsList"
            label="Name"
            :close-on-select="true"
            track-by="Id"
            placeholder="Toți autorii"
            select-label=""
            @search-change="GetListOfAuthors"
            @open="GetListOfAuthors()"
            @select="GetBlogs()"
            @remove="GetBlogs()"
          />
          <span
            class="btn-remove-multiselect"
            v-on:click="
              filter.Author = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="dropdown filter-border" :class="{ 'value-selected': filter.OrderBy }">
          <button
            class="btn-dropdown-outlined w-100 dropdown-toggle"
            type="button"
            id="orderByDropdownMenu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span v-if="filter.OrderBy"> Sortare: {{ filter.OrderBy.Name }} </span>
            <span v-else> Toate </span>
          </button>
          <ul class="dropdown-menu" aria-labelledby="orderByDropdownMenu">
            <li
              @click="
                filter.OrderBy = item;
                GetBlogs();
              "
              v-for="(item, index) in orderList"
              :key="index"
            >
              <span
                class="dropdown-item d-flex align-items-center"
                :class="{ active: filter.OrderBy == item }"
              >
                {{ item.Name }}
              </span>
              <hr
                class="dropdown-divider me-3 ms-3 border-color-blacktint-1"
                v-if="index < orderList.length - 1"
              />
            </li>
          </ul>
          <span
            class="btn-remove"
            v-on:click="
              filter.OrderBy = '';
              GetBlogs();
            "
          >
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-4 col-xl-3 mb-4" v-for="(blog, index) in blogs" :key="index">
        <div class="card">
          <div class="card-body">
            <img :src="$utils.ShowArticleImage(blog.ImgBase64)" class="blog-img" alt="..." />
            <div class="details">
              <div class="category">{{ blog.CategoryName }}</div>
              <div class="heading-07" style="height: 50px">
                {{ $utils.Ellipsify(blog.Title, 50) }}
              </div>
              <div class="row mt-3 mb-2">
                <div class="col d-flex align-items-center">
                  <img src="../../assets/images/icons/white-outline-calendar-20x20.svg" alt="..." />
                  <span class="ms-2 text-button-03 fw-normal">{{ blog.Date }}</span>
                </div>
                <div class="col-auto d-flex align-items-center">
                  <img src="../../assets/images/icons/white-outline-eye-20x20.svg" alt="..." />
                  <span class="ms-2 text-button-03 fw-normal">{{ blog.NumberOfViews }}</span>
                </div>
              </div>
              <router-link
                class="button d-flex align-items-center justify-content-center text-white text-center"
                :to="{
                  name: 'Article',
                  params: { articleName: $utils.StringToSlug(blog.Title), articolIdParam: blog.Id },
                }"
              >
                Citește articol
                <img src="../../assets/images/icons/arrow-right-20x20.svg" class="ms-1" alt="..." />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="blogs && blogs.length == 0 && (filter.SearchQuery || filter.FetchData)"
      class="mt-5 mb-5"
    >
      <not-found-component text="Nu există rezultate." />
    </div>
  </div>
  <!-- <Pagination
    class="mt-5 pt-5"
    :pageSize="pagination.PageSize"
    :totalItemCount="pagination.TotalItemCount"
    :totalPageCount="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    @select-page="SelectPage"
  /> -->
  <pagination-component
    :numberOfPages="pagination.TotalPageCount"
    :pageNumber="filter.PageNumber"
    :functionName="SelectPage"
  ></pagination-component>
</template>

<script>
/* eslint-disable */
import NotFoundComponent from '../../components/General/NotFoundComponent.vue';
import PaginationComponent from '../../components/General/PaginationComponent.vue';

export default {
  name: 'BlogsView',
  data() {
    return {
      orderList: [
        { Name: 'Alphabetic A-Z', Param: 'alphabetic' },
        { Name: 'Alphabetic Z-A', Param: 'alphabetic_desc' },
        { Name: 'Cele mai recente', Param: 'date' },
        { Name: 'Cele mai vechi', Param: 'date_desc' },
      ],
      blogs: [],
      pagination: {
        TotalItemCount: 0,
        TotalPageCount: 0,
        PageSize: 0,
      },
      filter: {
        PageSize: 12,
        PageNumber: 1,
        Category: '',
        Tags: [],
        Author: '',
        OrderBy: '',
        SearchQuery: '',
        FetchData: false,
      },
      categoriesList: [],
      tagsList: [],
      authorsList: [],
    };
  },
  components: {
    NotFoundComponent,
    PaginationComponent,
  },
  methods: {
    GetBlogs() {
      this.$store.state.loader = true;
      const searchParams = {
        ...(this.filter.Category ? { CategoryId: this.filter.Category.Id } : ''),
        ...(this.filter.SearchQuery ? { SearchQuery: this.filter.SearchQuery } : ''),
        ...(this.filter.OrderBy ? { OrderBy: this.filter.OrderBy.Param } : ''),
        ...(this.filter.Tags ? { Tags: this.filter.Tags } : ''),
        ...(this.filter.Author ? { AuthorId: this.filter.Author.Id } : ''),
        PageNumber: this.filter.PageNumber,
        PageSize: this.filter.PageSize,
      };
      this.$axios
        .get(`/api/Blog/getAll?${new URLSearchParams(searchParams)}`)
        .then((response) => {
          this.blogs = response.data.Items;
          this.pagination = response.data.PageDetails;
          this.$store.state.loader = false;
          this.filter.FetchData = true;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    async GetListOfCategories(search) {
      this.$store.state.loader = true;
      await this.$axios
        .get(`/api/Blog/getCategories?searchQuery=${search !== undefined ? search : ''}`)
        .then((response) => {
          this.categoriesList = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfTags(search) {
      this.$store.state.loader = true;
      this.$axios
        .get(`/api/Blog/getTags?searchQuery=${search !== undefined ? search : ''}`)
        .then((response) => {
          this.tagsList = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfAuthors(search) {
      this.$store.state.loader = true;
      this.$axios
        .get(`/api/Blog/getAuthors?searchQuery=${search !== undefined ? search : ''}`)
        .then((response) => {
          this.authorsList = response.data;
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          this.$utils.ToastNotify('error', 'Something went wrong.');
        });
    },
    SelectPage(nr) {
      this.filter.PageNumber = nr;
      this.GetBlogs();
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    await this.GetListOfCategories();
    if (this.$route.params && this.$route.params.categoryId) {
      const category = this.categoriesList.find(
        (categ) => categ.Id === parseInt(this.$route.params.categoryId, 10)
      );
      if (category) this.filter.Category = category;
    }
    this.GetBlogs();
  },
};
</script>

<style scoped>
.card a.button:hover {
  background-color: var(--whitetint-3);
}

.card a.button {
  padding: 8px 12px;
  border-radius: 8px;
}

.card:hover .blog-img {
  transform: scale(1.2) rotate(8.109deg);
}
.card:hover .details {
  height: 173px !important;
}

.card .card-body .details .category {
  font-size: 11.6px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: 2.4px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.card .card-body .details {
  position: absolute;
  background: var(--blacktint-5);
  backdrop-filter: blur(15px);
  bottom: 0;
  width: 100%;
  color: var(--neutral-1);
  padding: 12px;
  transition: 0.2s;
}
@media only screen and (min-width: 768px) {
  .card .card-body .details {
    height: 132px;
  }
}

.card .card-body .blog-img {
  width: 100%;
  height: 384px;
  object-fit: cover;
  object-position: center;
  transition: 0.3s;
}
.card .card-body {
  padding: 0px;
  position: relative;
}
.card {
  border: 0;
  border-radius: 12px;
  overflow: hidden;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1245px;
  }
}
</style>
